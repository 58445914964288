import http from '@/utils/request'

// 提交
export const submit = data => {
  return http({
    url: '/dealermanage/bpm/bpmJoinApi/submitFlow',
    data,
    method: 'POST'
  })
}
// 审批
export const submitApproveData = data => {
  return http({
    url: '/dealermanage/bpm/bpmJoinApi/submitApproveData',
    data,
    method: 'POST'
  })
}
// 保存到草稿
export const saveDraft = data => {
  return http({
    url: '/dealermanage/bpm/bpmJoinApi/saveDraft',
    data,
    method: 'POST'
  })
}

// 详情
export const detail = params => {
  return http({
    url: '/dealermanage/bpm/bpmJoinApi/details',
    params
  })
}
