<template>
  <div>
    <el-dialog
      :visible.sync="isShow"
      width="450px"
      @close="handleClose"
      :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="formOption" label-width="120px">
        <el-form-item
          label="品牌"
          prop="brandCode"
          :rules="[
            { required: true, message: '请选择品牌', trigger: 'change' }
          ]">
          <el-select
            v-model="formOption.brandCode"
            filterable
            remote
            placeholder="请选择品牌"
            popper-class="custom-business-select"
            @change="handleSelectDept">
            <el-option
              v-for="item in bigBrandMap"
              :key="item.id"
              :label="item.name"
              :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formOption.brandCode === 'C'"
          label="系列"
          prop="seriesName"
          :rules="[
            { required: true, message: '请选择系列', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.seriesName"
            placeholder="请选择系列"
            :get-data-list="params => getListPage(3, params)"
            :get-next-list="params => getListPage(3, params)"
            :getDataListParams="formOption.brandId"
            filterable
            clearable
            labelKey="name"
            valueKey="name"
            @changeSelectedItem="params => onChangeSelectedItem(params, 3)" />
        </el-form-item>
        <el-form-item
          v-if="formOption.brandCode === 'C'"
          label="经销商三级分类"
          prop="thirdChannelTypeName"
          :rules="[
            {
              required: true,
              message: '请选择经销商三级分类',
              trigger: 'change'
            }
          ]">
          <CommonSelect
            v-model="formOption.thirdChannelTypeName"
            placeholder="请选择经销商三级分类"
            :get-data-list="params => getListPage(4, params)"
            :get-next-list="params => getListPage(4, params)"
            filterable
            clearable
            labelKey="content"
            valueKey="content"
            @changeSelectedItem="params => onChangeSelectedItem(params, 4)" />
        </el-form-item>
        <el-form-item
          v-if="formOption.brandCode === 'C'"
          label="经销商区域归属"
          prop="dashangType"
          :rules="[
            {
              required: true,
              message: '请选择经销商区域归属',
              trigger: 'change'
            }
          ]">
          <CommonSelect
            v-model="formOption.dashangType"
            placeholder="请选择经销商区域归属"
            :get-data-list="params => getListPage(5, params)"
            :get-next-list="params => getListPage(5, params)"
            filterable
            clearable
            labelKey="content"
            valueKey="content"
            @changeSelectedItem="params => onChangeSelectedItem(params, 5)" />
        </el-form-item>
        <el-form-item
          label="大区"
          prop="administrativeRegionName"
          :rules="[
            { required: true, message: '请选择大区', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.administrativeRegionName"
            placeholder="请选择大区"
            :get-data-list="params => getList(1, params)"
            :get-next-list="params => getList(1, params)"
            remote
            filterable
            clearable
            labelKey="name"
            valueKey="name"
            @changeSelectedItem="params => onChangeSelectedItem(params, 1)" />
        </el-form-item>
        <el-form-item
          label="小区"
          prop="smallRegionName"
          :rules="[
            { required: true, message: '请选择小区', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.smallRegionName"
            placeholder="请选择小区"
            :get-data-list="params => getList(2, params)"
            :get-next-list="params => getList(2, params)"
            :getDataListParams="formOption.administrativeRegionId"
            remote
            filterable
            clearable
            labelKey="name"
            valueKey="name"
            @changeSelectedItem="params => onChangeSelectedItem(params, 2)" />
        </el-form-item>
        <el-form-item
          label="地区(省市区)"
          prop="authorizedCityName"
          :rules="[
            { required: true, message: '请选择地区', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.authorizedCityName"
            placeholder="请选择地区"
            :get-data-list="params => getListPage(6, params)"
            :get-next-list="params => getListPage(6, params)"
            remote
            filterable
            clearable
            labelKey="authorizedCityName"
            valueKey="authorizedCityName"
            @changeSelectedItem="params => onChangeSelectedItem(params, 6)" />
        </el-form-item>
        <!-- <el-form-item
          label="地区(省市区)"
          prop="provinceCode"
          :rules="[
            { required: true, message: '请选择地区', trigger: 'change' }
          ]">
          <el-cascader
            v-if="refresh"
            :props="addressProp"
            v-model="selectAddress"></el-cascader>
        </el-form-item> -->
        <el-form-item label="状态" prop="status">
          <el-switch
            v-model="formOption.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="0"
            :inactive-value="1">
          </el-switch>
        </el-form-item>
        <el-form-item
          label="部门"
          prop="bumenName"
          :rules="[
            { required: formOption.brandCode === 'C' ? true : false, message: '请选择部门', trigger: 'change' }
          ]">
          <CommonSelect
            v-model="formOption.bumenName"
            placeholder="请选择部门"
            :get-data-list="params => getListPage(7, params)"
            :get-next-list="params => getListPage(7, params)"
            filterable
            clearable
            labelKey="content"
            valueKey="content"
            @changeSelectedItem="params => onChangeSelectedItem(params, 7)" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSave">保存</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import requiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
// import { getAddress, getAreaByBrand } from '@/api/commonConfig/areaConfig'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import * as apiConfig from '@/api/commonConfig/areaConfigNew'
import { brandseriesList } from '@/api/common/'
export default {
  name: 'configDeptDialog',
  components: {
    requiredFormItemSelect,
    CommonSelect
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  props: {
    hidden: {
      type: Boolean,
      default: false,
      required: true
    },
    value: {
      type: Object,
      default: {},
      required: true
    },
    bigBrandMap: {
      type: Array,
      default: [],
      required: true
    }
  },
  watch: {
    hidden: {
      handler(value) {
        this.isShow = value
      },
      immediate: true
    },
    value: {
      async handler(value) {
        // this.formOption = value
        // console.log(value)
        if (value.hasOwnProperty('id')) {
          this.refresh = false
          this.$nextTick(() => {
            this.refresh = true
          })
          for (let key in value) {
            this.formOption[key] = value[key]
          }
          if (value.areaCode && value.areaCode !== '0') {
            this.selectAddress = [
              value.provinceCode,
              value.cityCode,
              value.areaCode
            ]
          } else if (!value.cityCode) {
            this.selectAddress = [value.provinceCode]
          } else {
            this.selectAddress = [value.provinceCode, value.cityCode]
          }
        } else {
          this.selectAddress = []
          this.initFormOption()
          this.$refs.ruleForm?.clearValidate()
        }
      },
      immediate: true,
      deep: true
    },
    selectAddress(value) {
      if (value.length > 0) {
        this.formOption.provinceCode = value[0]
        this.formOption.cityCode = value[1] || ''
        this.formOption.areaCode = value[2] || ''
        this.$refs.ruleForm.clearValidate('provinceCode')
      }
    }
  },
  data() {
    return {
      // Region: [], //大区
      // SmallRegion: [], //小区
      formOption: {},
      formOptionTemplate: {
        brandRegionCode: '',
        deptId: '', // 部门id
        deptCode: '', // 部门编号
        brandId: '', // 品牌id
        brandCode: '', // 品牌编号
        brandName:'', // 品牌名称
        administrativeRegionId: '', //大区id
        administrativeRegionCode: '', //大区编号
        administrativeRegionName: '', //大区名称
        smallRegionId: '', //小区id
        smallRegionCode: '', //小区编号
        smallRegionName: '', //小区名称
        provinceCode: '',
        cityCode: '',
        areaCode: '',
        status: 0,
        id: '',
        seriesId: '',
        seriesCode: '',
        seriesName: '',
        dashangCode: '',
        dashangType: '',
        thirdChannelTypeCode: '',
        thirdChannelTypeName: '',
        authorizedCityCode: '',
        authorizedCityName: '',
        bumenCode: '',
        bumenName: '',
      },
      isShow: false,
      loading: false,
      addressProp: {},
      selectAddress: [],
      refresh: true
      // deptData: [],
      // brandCodeParams: ''
      // bigBrandMap:[]
    }
  },
  async mounted() {
    // const res = await brandseriesList({ brandLevel: 0, ifDelete: 0 })
    // this.bigBrandMap = res.data
    this.addressProp = {
      lazy: true,
      checkStrictly: true,
      // emitPath: false,
      lazyLoad: async (node, resolve) => {
        const { level, value } = node
        let type = ''
        switch (level) {
          case 0:
            type = 'province'
            break
          case 1:
            type = 'city'
            break
          case 2:
            type = 'area'
            break
        }
        const res = await this.getAddressData({
          // type: type,
          parentCode: value || null
        })
        // console.log(res, 'res')
        const nodes = res.map(item => {
          if (item.code == '020') {
            return {
              value: item.code,
              label: item.content,
              leaf: true
            }
          } else {
            return {
              value: item.code,
              label: item.content,
              leaf: level >= 2
            }
          }
        })
        resolve(nodes)
      }
    }
    this.initFormOption()
    // const res = await this.getAddressData({
    //   type: 'province'
    // })
    // this.addressList = res
  },
  methods: {
    initFormOption() {
      this.formOption = { ...this.formOptionTemplate }
    },
    async getList(type, { page, getDataListParams }) {
      if (type === 2 && !getDataListParams) return []
      const res = await apiConfig.getAreaByBrandMatrix({
        level: type,
        currPage: page,
        pageSize: 10,
        parentId: getDataListParams
      })
      return {
        data: res.data.list || [],
        ...res.data
      }
    },
    onChangeSelectedItem(data, type) {
      switch (type) {
        case 1: // 大区选择
          this.formOption.administrativeRegionCode = data.code
          this.formOption.administrativeRegionId = data.id
          break
        case 2: // 小区选择
          this.formOption.smallRegionCode = data.code
          this.formOption.smallRegionId = data.id
          break
        case 3: // 系列选择
          this.formOption.seriesCode = data.code
          this.formOption.seriesId = data.id
          break
        case 4: // 经销商三级分类
          this.formOption.thirdChannelTypeCode = data.code
          break
        case 5: // 经销商区域归属
          this.formOption.dashangCode = data.code
          break
        case 6: // 经销商区域归属
          this.formOption.authorizedCityCode = data.authorizedCityCode
          break
        case 7: // 部门选择
          this.formOption.bumenCode = data.code
          break
        default:
          console.warn('未知的类型:', type)
          break
      }
    },
    async getListPage(type, { page, searchText, getDataListParams }) {
      switch (type) {
        case 3:
          if (!getDataListParams) return []
          return brandseriesList({
            brandLevel: 1,
            ifDelete: 0,
            parentId: getDataListParams
          })

        case 4:
          return apiConfig.getDictionary({
            type: 'dealer_thirdary_type',
            currPage: page,
            pageSize: 10
          })

        case 5:
          return apiConfig.getDictionary({
            type: 'dashang_type_code',
            currPage: page,
            pageSize: 10
          })

        case 6:
          return apiConfig.getAreaAddress({
            authorizedCityName:searchText,
            currPage: page,
            pageSize: 10
          })

        case 7:
          return apiConfig.getDictionary({
            type: 'bumen_type',
            currPage: page,
            pageSize: 10
          })

        default:
          console.warn('未知的类型:', type)
          return []
      }
    },
    //获取部门
    getListDetp({ page, searchText }) {
      // if (!getDataListParams) return []
      return apiConfig.getDept({
        currPage: page,
        pageSize: 10,
        name: searchText
        // brandCode: getDataListParams
      })
    },
    // 选择部门
    handleSelectDepts(data) {
      this.formOption.deptId = data?.id
    },
    handleSelectDept(data) {
      const option = this.bigBrandMap.find(item => item.code === data)
      this.formOption.brandId = option?.id
      this.formOption.brandName = option?.name
      const fieldsToClear = [
        'seriesCode',
        'seriesId',
        'thirdChannelTypeCode',
        'dashangCode',
        'seriesName',
        'thirdChannelTypeName',
        'dashangType'
      ]
      fieldsToClear.forEach(field => {
        this.formOption[field] = ''
      })
    },
    // 获取省市区
    async getAddressData(params) {
      const res = await apiConfig.getAreaAddress(params)
      // console.log(res);
      return res.data
    },
    handleClose() {
      this.$emit('onClose', false)
    },
    handleSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('changeValue', this.formOption)
          this.$emit('onSave')
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.el-form-item--small.el-form-item {
  // margin-bottom: 8px;
}
.select_item {
  margin-bottom: 20px;
}
</style>
