<script setup>
import * as apiConfig from '@/api/commonConfig/areaConfigNew'
import TablePro, { render } from '@/components/TablePro/index.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import { Message, MessageBox } from 'element-ui'
import { ref, reactive } from 'vue'
import ConfigDeptDialogNew from './ConfigDeptDialogNew.vue'
import { getStorage } from '@/utils/storage'

const tableProRef = ref(null)
const rowId = ref([])
const drawerShow = ref(false)
const formDataOption = reactive({
  formOption: {}
})
const props = defineProps({
  bigBrandMap: {
    type: Array,
    default: []
  },
  seriesMap: {
    type: Array,
    default: []
  }
})
const columns = [
  {
    title: '品牌ID',
    name: 'brandId',
    hideInTable: true
  },
  {
    title: '品牌编号',
    name: 'brandCode',
    hideInTable: true
  },
  {
    title: '品牌',
    name: 'brandName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '品牌',
    name: 'brandId',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      return (
        <RequiredFormItemSelect
          dataList={props.bigBrandMap}
          labelKey="name"
          valueKey="id"
          showLabel={false}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '大区ID',
    name: 'administrativeRegionId',
    hideInTable: true
  },
  {
    title: '大区编号',
    name: 'administrativeRegionCode',
    hideInTable: true
  },
  {
    title: '大区名称',
    name: 'administrativeRegionName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '大区名称',
    name: 'administrativeRegionCode',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page, searchText }) => {
        const res = await apiConfig.getAreaByBrandMatrix({
          currPage: page,
          pageSize: 10,
          level: 1,
          name: searchText
        })
        return {
          data: res.data.list || [],
          ...res.data
        }
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="name"
          valueKey="code"
          showLabel={false}
          filterable={true}
          remote
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '小区ID',
    name: 'smallRegionId',
    hideInTable: true
  },
  {
    title: '小区编号',
    name: 'smallRegionCode',
    hideInTable: true
  },
  {
    title: '小区名称',
    name: 'smallRegionName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '小区名称',
    name: 'smallRegionCode',
    search: true,
    hideInTable: true,
    hideInExport: true,
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page, searchText }) => {
        const res = await apiConfig.getAreaByBrandMatrix({
          currPage: page,
          pageSize: 10,
          level: 2,
          name: searchText
        })
        return {
          data: res.data.list || [],
          ...res.data
        }
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="name"
          valueKey="code"
          showLabel={false}
          filterable={true}
          remote
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '国家编码',
    name: 'countryCode',
    hideInTable: true
  },
  {
    title: '国家名称',
    name: 'countryName',
    hideInTable: true
  },
  {
    title: '省份编号',
    name: 'provinceCode',
    hideInTable: true
  },
  {
    title: '省份名称',
    name: 'provinceName',
    hideInTable: true
  },
  {
    title: '城市编号',
    name: 'cityCode',
    hideInTable: true
  },
  {
    title: '城市名称',
    name: 'cityName',
    hideInTable: true
  },
  {
    title: '地区编号',
    name: 'areaCode',
    hideInTable: true
  },
  {
    title: '地区名称',
    name: 'areaName',
    hideInTable: true
  },
  {
    title: '街镇编号',
    name: 'townCode',
    hideInTable: true
  },
  {
    title: '街镇名称',
    name: 'townName',
    hideInTable: true
  },
  {
    title: '地区（省市区）',
    name: 'authorizedCityName',
    hideInExport: true,
    tableColumnProps: { align: 'center' }
  },
  {
    title: '授权城市',
    name: 'authorizedCityName',
    hideInExport: true,
    hideInTable: true,
    tableColumnProps: { align: 'center' },
    search: {
      transform: e => {
        return { authorizedCityCode: e }
      }
    },
    tableColumnProps: { align: 'center' },
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        return apiConfig.getAreaAddress({
          currPage: page,
          pageSize: 10
        })
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="authorizedCityName"
          valueKey="authorizedCityCode"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '状态',
    name: 'status',
    valueType: 'select',
    search: true,
    tableColumnProps: { align: 'center' },
    valueEnum: [
      { value: 0, label: '启用中' },
      { value: 1, label: '已停用' }
    ],
    render: render((h, context) => {
      return (
        <el-switch
          value={context.data.status}
          active-value={0}
          inactive-value={1}
          on-change={async () => {
            try {
              await apiConfig.editAreaPersonMatrix({
                id: context.data.id,
                status: context.data.status === 0 ? 1 : 0,
                updateBy: getStorage('userInfo').account
              })
              Message.success('修改成功')
              tableProRef.value?.onRefreshTableList()
            } catch (err) {
              //
              console.log(err)
            }
          }}
        />
      )
    })
  },
  {
    title: '状态说明',
    name: 'statusExplain',
    hideInTable: true
  },
  {
    title: '系列',
    name: 'seriesName',
    search: {
      transform: e => {
        return { seriesId: e }
      }
    },
    tableColumnProps: { align: 'center' },
    renderFormItem: renderFormItem((h, context) => {
      return (
        <RequiredFormItemSelect
          dataList={props.seriesMap}
          labelKey="name"
          valueKey="id"
          showLabel={false}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '经销商三级分类',
    name: 'thirdChannelTypeName',
    tableColumnProps: { align: 'center' },
    search: {
      transform: e => {
        return { thirdChannelTypeCode: e }
      }
    },
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        return apiConfig.getDictionary({
          type: 'dealer_thirdary_type',
          currPage: page,
          pageSize: 10
        })
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="content"
          valueKey="code"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  {
    title: '经销商区域归属',
    name: 'dashangType',
    tableColumnProps: { align: 'center' },
    search: {
      transform: e => {
        return { dashangCode: e }
      }
    },
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        return apiConfig.getDictionary({
          type: 'dashang_type_code',
          currPage: page,
          pageSize: 10
        })
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="content"
          valueKey="code"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  },
  // {
  //   title: 'OA区域类别',
  //   name: 'administrativeRegionCode',
  //   tableColumnProps: { align: 'center' }
  // },
  {
    title: 'OA系统区域类别',
    name: 'oaRegionTypeName',
    tableColumnProps: { align: 'center' }
  },
  {
    title: '部门名称',
    name: 'bumenName',
    search: {
      transform: e => {
        return { bumenCode: e }
      }
    },
    tableColumnProps: { align: 'center' },
    renderFormItem: renderFormItem((h, context) => {
      const getSourceList = async ({ page }) => {
        return apiConfig.getDictionary({
          type: 'bumen_type',
          currPage: page,
          pageSize: 10
        })
      }
      return (
        <CommonSelect
          getDataList={getSourceList}
          getNextList={getSourceList}
          labelKey="content"
          valueKey="code"
          showLabel={false}
          filterable={true}
          on={context.$listeners}
          attrs={context.$attrs}
        />
      )
    })
  }
  // {
  //   title: '操作',
  //   tableColumnProps: { align: 'center', minWidth: '120' },
  //   render: render((h, context) => {
  //     return (
  //       <el-button link v-loading-click={() => edit(context.data.id)}>
  //         编辑
  //       </el-button>
  //     )
  //   })
  // }
]
const getTableList = async params => {
  const res = await apiConfig.getAreaPersonMatrix(params)
  return {
    data: res.data.list,
    totalCount: res.data.totalCount
  }
}
const add = () => {
  formDataOption.formOption = {}
  drawerShow.value = true
}

const allDel = async val => {
  rowId.value = val.map(item => {
    return item.id
  })
  MessageBox.confirm('批量停用数据, 是否继续?', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(async () => {
    try {
      await apiConfig.batchDisable(rowId.value)
      Message.success('成功')
      tableProRef.value.onRefreshTableList()
    } catch (error) {
      console.log(error)
    }
  })
}
const download = () => {
  apiConfig.downloadAreaPersonMatrixExcel()
}
const getFile = async e => {
  const userInfo = getStorage('userInfo')
  const excel = e.target.files[0]
  const formData = new FormData()
  formData.append('file', excel)
  formData.append('createBy', userInfo.account)
  formData.append('createName', userInfo.nickName)
  try {
    const res = await apiConfig.uploadAreaPersonMatrixExcel(formData)
    Message.success(res.msg)
  } catch (error) {
    console.log(error)
  }
  tableProRef.value.onRefreshTableList()
  e.target.value = ''
}
const handleSave = async () => {
  const formData = {
    ...formDataOption.formOption,
    createBy: getStorage('userInfo').account
  }
  console.log(formData, 'formData')
  // for (const key in formDataOption.formOption) {
  //   if (formDataOption.formOption[key]) {
  //     formData[key] = formDataOption.formOption[key]
  //   }
  // }
  try {
    if (!formData.id) {
      await apiConfig.addAreaPersonMatrix(formData)
      drawerShow.value = false
      tableProRef.value.onRefreshTableList()
    } else {
      await apiConfig.editAreaPersonMatrix(formData)
      drawerShow.value = false
      tableProRef.value.onRefreshTableList()
    }
    Message.success('保存成功')
  } catch (error) {}
}
const edit = async id => {
  drawerShow.value = true
  const detail = await getDetail(id)
  formDataOption.formOption = detail
}
const getDetail = async id => {
  const res = await apiConfig.areaPersonMatrixDetail(id)
  return res.data
}
</script>

<template>
  <div>
    <TablePro
      ref="tableProRef"
      :columns="columns"
      :request="getTableList"
      :actions="['export']"
      exportFileName="区域部门配置"
      :headerCellStyle="{ background: '#e2f2fb', color: '#393F4D' }"
      auto-width-cell>
      <template #actionArea="{ selectedList }">
        <div class="btnList">
          <el-button type="primary" @click="add()">新增</el-button>
          <el-button type="danger" @click="allDel(selectedList)"
            >批量停用</el-button
          >
          <el-button icon="el-icon-download" @click="download()"
            >下载模板</el-button
          >
          <div class="upload">
            <el-button type="primary">导入</el-button>
            <input type="file" name="" id="" class="file" @change="getFile" />
          </div>
        </div>
      </template>
    </TablePro>
    <ConfigDeptDialogNew
      :hidden="drawerShow"
      v-model="formDataOption.formOption"
      @onSave="handleSave"
      :bigBrandMap="bigBrandMap"
      @onClose="drawerShow = false" />
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/mixin.scss';

.btnList {
  @include flex(flex-start, center);
  // height: 60px;

  .upload {
    // @include flex(center, center);
    position: relative;
    overflow: hidden;
    margin-left: 10px;
    width: auto;
    height: auto;
    display: inline-block;
    // cursor: pointer;
    // top: 10px;
    .file {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
</style>
