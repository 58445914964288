import { getMasterDataList, getDealerList } from '@/api/businessOos'
import {
  getAuthorizedCityData,
  getShopBrandData,
  getDealerBossInfo,
  getShopList,
  getBrandLeagueCoefficientInfo
} from '@/api/common'
import { getBusiness } from '@/api/user'
const state = {
  isOA: false, // 是否为oa模块
  cityList: [], //授权城市列表
  brandList: [], //售达方授权城市下对应品牌列表
  dealerBusinessParams: {}, //选中原售达方
  authorizedCityParams: {}, //根据原售达方选中授权城市
  brandIdList: [], //原售达方-授权城市下选中的转让品牌
  dealerBossInfo: null, //根据身份证查询经销商基础信息
  businessLicense: null, //根据社会统一信用代码查询营业执照信息
  shopList: [], //根据原售达方转让品牌查询门店
  transferShopStatus: false, //选择是否转让门店
  historicalData: {}, //获取详情
  status: 10, //判断状态
  senderList: [],
  brandLeagueCoefficientInfo: null //财务品牌加盟系数
}
const getters = {
  isOA: state => state.isOA,
  //代理品牌
  agencyBrandList: state => {
    let arr = []
    let arrId = []
    if (state.brandList.length > 0) {
      state.brandList.map(item => {
        if (item.ifEnabled === 0 && !arrId.includes(item.brandId)) {
          arrId.push(item.brandId)
          arr.push(item.name)
        }
      })
      if (state.status === 40) {
        const { brandIdList } = state.historicalData
        if (brandIdList.length > 0) {
          state.brandList.map(item => {
            if (
              brandIdList.includes(item.brandId) &&
              !arrId.includes(item.brandId)
            ) {
              arrId.push(item.brandId)
              arr.push(item.name)
            }
          })
        }
      }
      // console.log(arr)
    }
    return arr
  }
}
const mutations = {
  setSenderList: (state, arr) => {
    state.senderList = arr
  },
  setStatus: (state, status) => {
    state.status = status
  },
  //存详情
  setHistoricalData: (state, obj) => {
    state.historicalData = obj
  },
  //存选中售达方的授权城市
  setCityList: (state, list) => {
    state.cityList = list
  },
  //存选中售达方的授权城市品牌
  setBrandList: (state, list) => {
    state.brandList = list
  },
  //存选中售达方
  setDealerBusinessParams: (state, obj) => {
    state.dealerBusinessParams = obj
  },
  //存选中售达方的授权城市
  setAuthorizedCityParams: (state, obj) => {
    state.authorizedCityParams = obj
  },
  //存选中的转让品牌
  setBrandIdList: (state, arr) => {
    state.brandIdList = arr
  },
  //存经销商基础信息
  setDealerBossInfo: (state, obj) => {
    state.dealerBossInfo = obj
  },
  //存根据社会统一信用代码查询营业执照信息
  setBusinessLicense: (state, obj) => {
    state.businessLicense = obj
  },
  //存根据售达方查询门店
  setShopList: (state, arr) => {
    state.shopList = arr
  },
  //修改转让门店状态
  setTransferShopStatus: (state, boolean) => {
    state.transferShopStatus = boolean
  },
  // 设置oa标识
  setIsOa(state, flag) {
    state.isOA = flag
  },
  // 设置品牌加盟系数
  setBrandLeagueCoefficientInfo(state, obj) {
    state.brandLeagueCoefficientInfo = obj
  }
}
const actions = {
  // 获取财务品牌加盟系数
  async getBrandLeagueCoefficientInfo({ state, commit }, params) {
    const { creditCode } = params
    if (!creditCode) return
    const businessRes = await getBusiness({ creditCode })
    const bossId = businessRes.data.length ? businessRes.data[0].bossId : ''
    const authorizedCityCode = state.authorizedCityParams.authorizedCityCode
    const marketLevelCode = state.authorizedCityParams.marketLevelCode
    const bigBrandCode = []
    const brandCode = []
    const seriesIdList = state.brandIdList
    const { data } = await getDealerList({
      boss_id: bossId,
      authorized_city_code: authorizedCityCode
    })
    if (!marketLevelCode && !bigBrandCode.length && !brandCode.length) return
    state.brandList.forEach(brand => {
      if (state.brandIdList.includes(brand.brandId)) {
        brandCode.push(brand.brandCode)
        if (bigBrandCode.indexOf(brand.bigBrandCode) === -1) {
          bigBrandCode.push(brand.bigBrandCode)
        }
      }
    })
    const res = await getBrandLeagueCoefficientInfo({
      marketLevelCode,
      bigBrandCode: bigBrandCode.join(','),
      brandCode: brandCode.join(','),
      seriesIdList,
      dealerId: data.length ? data[0].id : ''
    })
    commit('setBrandLeagueCoefficientInfo', res.data)
    return res.data
  },
  //查询送达方
  async getSenderList({ state, commit }, obj) {
    await getMasterDataList(obj).then(res => {
      if (res.data.length) {
        let senderList = res.data.map(item => {
          return item.authorizedName
        })
        commit('setSenderList', senderList)
      } else {
        commit('setSenderList', [])
      }
    })
  },
  //获取售达方授权城市
  getAuthorizedCityData({ commit }, val) {
    return new Promise((resolve, reject) => {
      getAuthorizedCityData({ businessLicenseId: val }).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            commit('setCityList', res.data)
          } else {
            commit('setCityList', [])
          }
          resolve()
        } else {
          reject()
        }
      })
    })
  },
  //获取售达方-授权城市-对应品牌列表
  getShopBrandData({ state, commit }, obj) {
    return new Promise((resolve, reject) => {
      getShopBrandData(obj).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            commit('setBrandList', res.data)
          } else {
            commit('setBrandIdList', [])
            commit('setBrandList', [])
          }
          resolve()
        } else {
          reject()
        }
      })
    })
  },
  //根据身份证查询经销商基础信息
  getDealerBossInfo({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('setDealerBossInfo', null)
      getDealerBossInfo(params).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            commit('setDealerBossInfo', res.data[0])
          } else {
            commit('setDealerBossInfo', null)
          }
          resolve()
        } else {
          reject()
        }
      })
    })
  },
  //根据社会统一信用代码查询营业执照信息
  getBusinessLicense({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit('setBusinessLicense', null)
      getBusiness(params).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            commit('setBusinessLicense', res.data[0])
          } else {
            commit('setBusinessLicense', null)
          }
          resolve()
        } else {
          reject()
        }
      })
      // console.log(res,params)
    })
  },
  //根据售达方-转让品牌-查询门店
  async getShopList({ state, commit }, shopStatus = 0) {
    return new Promise((resolve, reject) => {
      commit('setShopList', [])
      const params = {
        businessLicenseId: state.dealerBusinessParams.id,
        authorizedCityCode: state.authorizedCityParams.authorizedCityCode,
        shopStatus
      }
      getShopList(params).then(res => {
        if (res.code === 0) {
          if (res.data.length) {
            commit('setShopList', res.data)
          } else {
            commit('setShopList', [])
          }
          resolve()
        } else {
          reject()
        }
      })
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
