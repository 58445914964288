export default [
  {
    path: 'dataManage',
    name: 'DataManage',
    component: () => import('@/views/commonConfig/dataManage'),
    meta: { title: '流程数据监控' }
  },
  {
    path: 'orderSpecialist',
    name: 'OrderSpecialist',
    component: () => import('@/views/commonConfig/orderSpecialist'),
    meta: { title: '订单专员' }
  },
  {
    path: 'feedBack',
    name: 'FeedBack',
    component: () => import('@/views/commonConfig/feedBack'),
    meta: { title: '意见反馈' }
  },
  {
    path: 'feedBackForm',
    name: 'FeedBackForm',
    component: () => import('@/views/commonConfig/feedBackForm'),
    meta: { title: '意见反馈表' }
  },
  {
    path: 'dataJsonTable',
    name: 'DataJsonTable',
    component: () => import('@/views/commonConfig/dataJsonTable'),
    meta: { title: '数据表' }
  },
  {
    path: 'dataAudit',
    name: 'dataAudit',
    component: () => import('@/views/commonConfig/dataAudit'),
    meta: { title: '数据审计' }
  },
  {
    path: 'areaShip',
    name: 'AreaShip',
    component: () => import('@/views/commonConfig/areaShip'),
    meta: { title: '区域关系配置' }
  },
  {
    path: 'areaShipNew',
    name: 'areaShipNew',
    component: () => import('@/views/commonConfig/areaShipNew'),
    meta: { title: '新区域关系配置' }
  },
  {
    path: 'coefficientManagement',
    name: 'commonConfig/coefficientManagement',
    component: () =>
      import('@/views/commonConfig/coefficientManagement/index.vue'),
    meta: { title: '系数管理' }
  },
  {
    path: 'decorationSubsidyManagement',
    name: 'commonConfig/decorationSubsidyManagement',
    component: () =>
      import('@/views/commonConfig/decorationSubsidyManagement/index.vue'),
    meta: { title: '装修补贴类型管理' }
  },
  {
    path: 'shopDecorationSubsidyManagement',
    name: 'commonConfig/shopDecorationSubsidyManagement',
    component: () =>
      import('@/views/commonConfig/shopDecorationSubsidyManagement.vue'),
    meta: { title: '门店装修补贴类型管理' }
  }
]
