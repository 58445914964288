import http from '@/utils/request'

// 订单专员列表
export const attacheDataList = params =>
  http({ url: '/dealermanage/boss/attache', params })

// 订单主管列表
export const partList = params => http({url:'/dealermanage/boss/getPartList',params})

// 角色
export const roleDataList = params =>
  http({ url: '/dealermanage/boss/rolelistpage', params })

// 人员
export const userDataList = params =>
  http({ url: 'user/account/effective', params })

//保存
export const saveMessage = (url,data) =>
  http({ url, data, method: 'post' })

// 详情
export const attacheDetail = params =>
  http({ url: '/dealermanage/boss/role', params })

// 角色列表
export const roleList = params =>
  http({ url: '/dealermanage/role/listpage', params })
