var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.isShow,"width":"450px","close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShow=$event},"close":_vm.handleClose}},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formOption,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"品牌","prop":"brandCode","rules":[
          { required: true, message: '请选择品牌', trigger: 'change' }
        ]}},[_c('el-select',{attrs:{"filterable":"","remote":"","placeholder":"请选择品牌","popper-class":"custom-business-select"},on:{"change":_vm.handleSelectDept},model:{value:(_vm.formOption.brandCode),callback:function ($$v) {_vm.$set(_vm.formOption, "brandCode", $$v)},expression:"formOption.brandCode"}},_vm._l((_vm.bigBrandMap),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.code}})}),1)],1),(_vm.formOption.brandCode === 'C')?_c('el-form-item',{attrs:{"label":"系列","prop":"seriesName","rules":[
          { required: true, message: '请选择系列', trigger: 'change' }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择系列","get-data-list":params => _vm.getListPage(3, params),"get-next-list":params => _vm.getListPage(3, params),"getDataListParams":_vm.formOption.brandId,"filterable":"","clearable":"","labelKey":"name","valueKey":"name"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 3)},model:{value:(_vm.formOption.seriesName),callback:function ($$v) {_vm.$set(_vm.formOption, "seriesName", $$v)},expression:"formOption.seriesName"}})],1):_vm._e(),(_vm.formOption.brandCode === 'C')?_c('el-form-item',{attrs:{"label":"经销商三级分类","prop":"thirdChannelTypeName","rules":[
          {
            required: true,
            message: '请选择经销商三级分类',
            trigger: 'change'
          }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择经销商三级分类","get-data-list":params => _vm.getListPage(4, params),"get-next-list":params => _vm.getListPage(4, params),"filterable":"","clearable":"","labelKey":"content","valueKey":"content"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 4)},model:{value:(_vm.formOption.thirdChannelTypeName),callback:function ($$v) {_vm.$set(_vm.formOption, "thirdChannelTypeName", $$v)},expression:"formOption.thirdChannelTypeName"}})],1):_vm._e(),(_vm.formOption.brandCode === 'C')?_c('el-form-item',{attrs:{"label":"经销商区域归属","prop":"dashangType","rules":[
          {
            required: true,
            message: '请选择经销商区域归属',
            trigger: 'change'
          }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择经销商区域归属","get-data-list":params => _vm.getListPage(5, params),"get-next-list":params => _vm.getListPage(5, params),"filterable":"","clearable":"","labelKey":"content","valueKey":"content"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 5)},model:{value:(_vm.formOption.dashangType),callback:function ($$v) {_vm.$set(_vm.formOption, "dashangType", $$v)},expression:"formOption.dashangType"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"大区","prop":"administrativeRegionName","rules":[
          { required: true, message: '请选择大区', trigger: 'change' }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择大区","get-data-list":params => _vm.getList(1, params),"get-next-list":params => _vm.getList(1, params),"remote":"","filterable":"","clearable":"","labelKey":"name","valueKey":"name"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 1)},model:{value:(_vm.formOption.administrativeRegionName),callback:function ($$v) {_vm.$set(_vm.formOption, "administrativeRegionName", $$v)},expression:"formOption.administrativeRegionName"}})],1),_c('el-form-item',{attrs:{"label":"小区","prop":"smallRegionName","rules":[
          { required: true, message: '请选择小区', trigger: 'change' }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择小区","get-data-list":params => _vm.getList(2, params),"get-next-list":params => _vm.getList(2, params),"getDataListParams":_vm.formOption.administrativeRegionId,"remote":"","filterable":"","clearable":"","labelKey":"name","valueKey":"name"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 2)},model:{value:(_vm.formOption.smallRegionName),callback:function ($$v) {_vm.$set(_vm.formOption, "smallRegionName", $$v)},expression:"formOption.smallRegionName"}})],1),_c('el-form-item',{attrs:{"label":"地区(省市区)","prop":"authorizedCityName","rules":[
          { required: true, message: '请选择地区', trigger: 'change' }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择地区","get-data-list":params => _vm.getListPage(6, params),"get-next-list":params => _vm.getListPage(6, params),"remote":"","filterable":"","clearable":"","labelKey":"authorizedCityName","valueKey":"authorizedCityName"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 6)},model:{value:(_vm.formOption.authorizedCityName),callback:function ($$v) {_vm.$set(_vm.formOption, "authorizedCityName", $$v)},expression:"formOption.authorizedCityName"}})],1),_c('el-form-item',{attrs:{"label":"状态","prop":"status"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-value":0,"inactive-value":1},model:{value:(_vm.formOption.status),callback:function ($$v) {_vm.$set(_vm.formOption, "status", $$v)},expression:"formOption.status"}})],1),_c('el-form-item',{attrs:{"label":"部门","prop":"bumenName","rules":[
          { required: _vm.formOption.brandCode === 'C' ? true : false, message: '请选择部门', trigger: 'change' }
        ]}},[_c('CommonSelect',{attrs:{"placeholder":"请选择部门","get-data-list":params => _vm.getListPage(7, params),"get-next-list":params => _vm.getListPage(7, params),"filterable":"","clearable":"","labelKey":"content","valueKey":"content"},on:{"changeSelectedItem":params => _vm.onChangeSelectedItem(params, 7)},model:{value:(_vm.formOption.bumenName),callback:function ($$v) {_vm.$set(_vm.formOption, "bumenName", $$v)},expression:"formOption.bumenName"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSave}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取消")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }