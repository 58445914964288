<template>
  <div>
    <FlowForm
      ref="flowFormRef"
      title="经典VMD标准外申请流程"
      fd-form-template-code="distributor-JXS036"
      :tips="[
        '1、延期重装：门店到期装修申请延期重装',
        '2、不按终端4S标准上样：例如①不按（装修材料、饰品、产品）标准清单下单；②自行采购物料等',
        '3、与其他品牌相关联：例如①与沙发或其他非经典品牌互通，开X米门洞；②摆放沙发或其他非经典品牌画面/沙发；③门店设计按其他非经典品牌门店风格设计等',
        '4、设计 / 装修未按标准执行：例如①门头标识特殊；②发光字不带中文字；③摆放茶台；④按商场要求特殊设计 / 外立面使用商场统一材料；⑤4.5风格门店中设计一个5.0风格的展示区；⑥背景柜、样板展示柜、衣柜或其他不重装等',
        '5、门店性质变更及认定:例如①若已装修或正在装修门店，因经销商原因，需要调整门店风格或涉及到需缩小门店面积做其他用途;②装企店不装门头仍算有效门店等',
        '6、样品及饰品未捆绑下单：例如①装修物料没有跟饰品捆绑下单，需先下装修物料，后期再下饰品等',
        '7、其他（从严审核）：除上述类型之外的其他类型，请谨慎选择，将从严审核'
      ]"
      :model="form"
      :is-edit="[0, 20].includes(Number(form.status))"
      :get-detail="getDetail"
      :on-submit="onSubmit"
      :approval-data="form.approveData"
      :on-approval="onApproval">
      <div>
        <RequiredFormItemInput
          delayUpdate
          label="标题"
          class="formItem"
          :value="form.title"
          disabled />
        <RequiredFormItemInput
          delayUpdate
          label="单号"
          class="formItem"
          :value="form.orderNumber"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          delayUpdate
          label="招商单号"
          class="formItem"
          :value="form.zsOrderNumber"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单人工号"
          :value="form.optJobNumber"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单人"
          :value="form.optUserName"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单部门"
          :value="form.optDeptName"
          placeholder="自动生成"
          disabled />
        <RequiredFormItemInput
          class="formItem"
          label="提单日期"
          :value="form.optTime"
          placeholder="自动生成"
          disabled />
        <HeaderTitle style="margin-top: 20px" title="经销商&门店信息" />
        <RequiredFormItemSelect
          prop="businessLicenseId"
          class="formItem"
          label="售达方编号"
          v-model="form.businessLicenseId"
          label-key="licenseNumber"
          value-key="id"
          remote
          filterable
          clearable
          :selectProps="{
            'popper-class': 'custom-business-select'
          }"
          :getDataList="getBusinessLicenseDataList"
          @onOptionsListNotFound="onBusinessLicenseNotFound"
          @onChangeItem="onBusinessLicenseChangeItem">
          <template v-slot="{ scope }">
            <span>{{ scope.row.licenseNumber }}</span>
            <small>{{ scope.row.businessLicenseName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          prop="businessLicenseName"
          label="售达方名称"
          v-model="form.businessLicenseName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemSelect
          prop="authorizedBrandNum"
          class="formItem"
          label="送达方编号"
          v-model="form.authorizedBrandNum"
          label-key="shopBrandNumber"
          value-key="shopBrandNumber"
          remote
          filterable
          clearable
          :disabled="!form.businessLicenseId"
          :selectProps="{
            'popper-class': 'custom-business-select'
          }"
          :getDataListParams="getAuthorizedbrandParams"
          :getDataList="getAuthorizedBrandDataList"
          @onOptionsListNotFound="onAuthorizedBrandNotFound"
          @onChangeItem="onAuthorizedBrandChangeItem">
          <template v-slot="{ scope }">
            <span>{{ scope.row.shopBrandNumber }}</span>
            <small>{{ scope.row.authorizedName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          prop="authorizedBrandName"
          label="送达方名称"
          v-model="form.authorizedBrandName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemSelect
          prop="shopNumber"
          class="formItem"
          label="门店编号"
          v-model="form.shopNumber"
          label-key="shopNumber"
          value-key="shopNumber"
          remote
          filterable
          clearable
          :disabled="!form.authorizedBrandNum"
          :selectProps="{
            'popper-class': 'custom-business-select'
          }"
          :getDataListParams="getShopParams"
          :getDataList="getShopDataList"
          @onOptionsListNotFound="onShopNotFound"
          @onChangeItem="onShopChangeItem">
          <template v-slot="{ scope }">
            <span>{{ scope.row.shopNumber }}</span>
            <small>{{ scope.row.shopName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemSelect
          prop="shopManageFormNumber"
          :required="false"
          class="formItem"
          label="经销商门店管理申请"
          v-model="form.shopManageFormNumber"
          label-key="title"
          value-key="orderNumber"
          remote
          filterable
          clearable
          :selectProps="{
            'popper-class': 'custom-business-select'
          }"
          :disabled="!form.shopNumber"
          :getDataListParams="getDealerShopManageFormParams"
          :getDataList="getDealerShopManageFormList"
          @onOptionsListNotFound="onDealerShopManageFormNotFound"
          @onChangeItem="onChangeDealerShopManageForm">
          <template v-slot="{ scope }">
            <span>{{ scope.row.orderNumber }}</span>
            <small>{{ scope.row.title }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          class="formItem"
          :required="false"
          prop="shopManageFormZSNumber"
          label="招商单号"
          v-model="form.shopManageFormZSNumber"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          :required="false"
          prop="dianjiangDesignerName"
          label="设计师"
          v-model="form.dianjiangDesignerName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="bossNumber"
          label="实控人编号"
          v-model="form.bossNumber"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="bossName"
          label="实控人名称"
          v-model="form.bossName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="seriesNames"
          label="已代理品牌"
          v-model="form.seriesNames"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="smallRegionName"
          label="营销中心"
          v-model="form.smallRegionName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="authorizedCityName"
          label="授权城市"
          v-model="form.authorizedCityName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="marketLevelName"
          label="城市等级"
          v-model="form.marketLevelName"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="marketType"
          label="卖场类型"
          v-model="form.marketType"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="shopAddress"
          label="门店地址"
          v-model="form.shopAddress"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="上一年业绩排名"
          v-model="form.remarkMap.lastYearShipInvRbAddAmtCur"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="上一年出货业绩"
          v-model="form.remarkMap.lastYearRowIndex"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="上一年业绩增长率"
          v-model="form.remarkMap.lastYearGrowthRate"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="addShopCount"
          label="本年净增长门店数"
          :rules="[integerOrZeroRule]"
          v-model="form.addShopCount"
          delay-update />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="本年业绩排名"
          v-model="form.remarkMap.thisYearShipInvRbAddAmtCur"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="本年出货业绩"
          v-model="form.remarkMap.thisYearRowIndex"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="remarkMap"
          label="本年业绩增长率"
          v-model="form.remarkMap.thisYearGrowthRate"
          placeholder="自动带出"
          delay-update
          disabled />
        <RequiredFormItemInput
          class="formItem"
          prop="lateShopCount"
          label="到期未重装门店数"
          :rules="[integerOrZeroRule]"
          v-model="form.lateShopCount"
          delay-update />
        <RequiredFormItemSelect
          prop="approveData.applicationType"
          class="formItem"
          label="申请类型"
          :dataList="applicationTypeOptions"
          labelKey="label"
          valueKey="value"
          clearable
          v-model="form.approveData.applicationType" />
        <RequiredFormItemUploadFile
          prop="approveData.storeFloorPlan"
          v-model="form.approveData.storeFloorPlan"
          class="formItem"
          limit-size="400"
          label="门店平面图"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          prop="approveData.mallLayout"
          :required="false"
          v-model="form.approveData.mallLayout"
          class="formItem"
          limit-size="400"
          label="商场品牌布局图"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          prop="approveData.onSitePV"
          v-model="form.approveData.onSitePV"
          class="formItem"
          limit-size="400"
          label="现场照片/视频"
          auto-upload>
          <template v-slot:tip>
            <div>单个文件不能超过400M</div>
          </template>
        </RequiredFormItemUploadFile>
        <RequiredFormItemSelect
          class="formItem"
          :required="false"
          v-model="form.approveData.cCpersonnel"
          prop="approveData.cCpersonnel"
          label="抄送人员"
          labelKey="personName"
          valueKey="personNum"
          :selectProps="{
            filterable: true,
            remote: true,
            'popper-class': 'custom-business-select',
            multiple: true
          }"
          :valueTranslate="onJindieValueTranslate"
          :valueOutputTranslate="onJindieValueOutputTranslate"
          :getDataList="requestJindieDataList"
          @onOptionsListNotFound="onJindieDataNotFound">
          <template v-slot="{ scope }">
            <span>{{ scope.row.personNum }}</span>
            <small>{{ scope.row.personName }}</small>
          </template>
        </RequiredFormItemSelect>
        <RequiredFormItemInput
          prop="approveData.applicationReason"
          delay-update
          v-model="form.approveData.applicationReason"
          style="width: 100%"
          class="formItem"
          placeholder="请清楚、具体地描述申请事项的理由、原因。"
          :inputProps="{
            type: 'textarea',
            resize: 'none',
            rows: 6,
            maxlength: '500',
            showWordLimit: true
          }"
          label="申请事由" />
        <RequiredFormItemInput
          prop="approveData.remark"
          delay-update
          v-model="form.approveData.remark"
          style="width: 100%"
          :required="false"
          class="formItem"
          placeholder="如有特别需要的注意事项，请具体描述。"
          :inputProps="{
            type: 'textarea',
            resize: 'none',
            rows: 6,
            maxlength: '500',
            showWordLimit: true
          }"
          label="备注说明" />
      </div>
    </FlowForm>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'
import dateFormat from 'dateformat'
import FlowForm from '@/views/bpmFlow/components/flowForm.vue'
import {
  RequiredFormItemInput,
  RequiredFormItemSelect,
  RequiredFormItemUploadFile
} from '@/components/CustomFormItem'
import HeaderTitle from '@/views/bpmFlow/components/headerTitle.vue'
import store from '@/store'
import { useRoute } from '@/utils/router'
import {
  getAuthorizedbrandListPage,
  getShopByAuthorizedbrandListPage,
  requestGetSizeBarListByDeptMerge,
  requestJinDieHr
} from '@/api/common'
import { requestGetPickUpByAuthorizedBrand } from './api/index'
import { getShopListPage } from '@/api/business/draft'
import { requestGetFlowDetail } from './api/storeManagementFlowApi'
import * as classicalVMDApi from '@/views/bpmFlow/api/classicalVMDApi'
import { license } from '@/api/attractPlatform/business'
const $route = useRoute()
const routeQuery = $route.query
const flowFormRef = ref()
const user = store.state.user.userInfo
const isOA = computed(() => store.state.dealershipTransfer.isOA)
const isMobile = computed(
  () => store.state.app.device === 'mobile' || store.state.app.isWxworkMoblie
)
const integerOrZeroRule = {
  message: '填写整数或0',
  validator: (rule, value, callback) => {
    if (value != '' && !/^(0|([1-9]\d*))$/.test(value)) {
      callback(rule.message)
    } else {
      callback()
    }
  }
}
const applicationTypeOptions = [
  { label: '延期重装', value: 1 },
  { label: '不按终端4S标准上样', value: 2 },
  { label: '与其他品牌相关联', value: 3 },
  { label: '设计/装修未按标准执行', value: 4 },
  { label: '门店性质变更及认定', value: 5 },
  { label: '样品及饰品未捆绑下单', value: 6 },
  { label: '其他（从严审核）', value: 7 }
]
const form = reactive({
  id: '', //表单id
  title: '经典VMD标准外申请流程', //标题
  orderNumber: '', //单号
  zsOrderNumber: '', //招商单号
  optJobNumber: '', //提单人工号
  optUserId: '', //提单人id
  optUserName: '', //提单人
  optDeptId: '', //提单部门id
  optDeptName: '', //提单部门
  optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), //提单日期
  businessLicenseId: '', //售达方ID
  licenseNumber: '', //售达方编号
  businessLicenseName: '', //售达方名称
  authorizedBrandNum: '', //送达方编号
  authorizedBrandName: '', //送达方名称
  shopId: '', //门店ID
  shopNumber: '', //门店编号
  shopManageFormNumber: '', //门店管理流程单号
  shopManageFormTitle: '', //门店管理流程招商标题
  shopManageFormZSNumber: '', //门店管理流程招商单号
  dianjiangDesigner: '', //点将设计师 （工号）
  dianjiangDesignerName: '', //点将设计师 名称
  dianjiangDesignerPhone: '', //点将设计师 手机号
  bossId: '', //实控人ID
  bossName: '', //实控人名称
  bossNumber: '', //实控人编号
  seriesNames: '', //已代理品牌 多个用英文逗号隔开
  smallRegionCode: '', //小区编码 营销中心编码
  smallRegionName: '', //小区名称 营销中心名称
  authorizedCityCode: '', //授权城市编码
  authorizedCityName: '', //授权城市名称
  marketLevelCode: '', //城市等级编码
  marketLevelName: '', //城市等级名称
  marketType: '', //卖场类型
  shopAddress: '', //门店地址
  addShopCount: '', //本年净增长门店数
  lateShopCount: '', //到期未重装门店数
  remarkMap: {
    /**上一年业绩排名 */
    lastYearShipInvRbAddAmtCur: '',
    /**上一年出货业绩 */
    lastYearRowIndex: '',
    /**上一年业绩增长率 */
    lastYearGrowthRate: '',
    /**本年业绩排名 */
    thisYearShipInvRbAddAmtCur: '',
    /**本年出货业绩 */
    thisYearRowIndex: '',
    /**本年业绩增长率 */
    thisYearGrowthRate: ''
  }, //业绩数据
  approveData: {
    /*申请类型 */
    applicationType: '',
    /*门店平面图 */
    storeFloorPlan: [],
    /*商场品牌布局图 */
    mallLayout: [],
    /*现场照片/视频 */
    onSitePV: [],
    /*申请事由 */
    applicationReason: '',
    /*备注说明 */
    remark: '',
    /*抄送人员 */
    cCpersonnel: ''
  }, //bpm审批字段
  applyOrderModel: {} //bpm必填
})
/**重新格式化业绩数据*/
function getRemarkMap(originalData) {
  if (!originalData.length) {
    return {
      /**上一年业绩排名 */
      lastYearShipInvRbAddAmtCur: '',
      /**上一年出货业绩 */
      lastYearRowIndex: '',
      /**上一年业绩增长率 */
      lastYearGrowthRate: '',
      /**本年业绩排名 */
      thisYearShipInvRbAddAmtCur: '',
      /**本年出货业绩 */
      thisYearRowIndex: '',
      /**本年业绩增长率 */
      thisYearGrowthRate: ''
    }
  }
  const data = originalData.sort(
    (a, b) => Number(b.year_desc) - Number(a.year_desc)
  )
  return {
    lastYearShipInvRbAddAmtCur: data[1].ship_inv_rb_add_amt_cur,
    lastYearRowIndex: data[1].row_index,
    lastYearGrowthRate: `${(data[1].growth_rate * 100).toFixed(2)}%`,
    thisYearShipInvRbAddAmtCur: data[0].ship_inv_rb_add_amt_cur,
    thisYearRowIndex: data[0].row_index,
    thisYearGrowthRate: `${(data[0].growth_rate * 100).toFixed(2)}%`
  }
}
const {
  getBusinessLicenseDataList,
  onBusinessLicenseNotFound,
  onBusinessLicenseChangeItem
} = useBusinessLicense()
function useBusinessLicense() {
  async function getBusinessLicenseDataList({ page, searchText }) {
    const res = await license({
      currPage: page,
      key: searchText
    })
    return res
  }
  async function onBusinessLicenseNotFound(
    { value: businessLicenseId },
    callback
  ) {
    const res = await license({
      businessLicenseId
    })
    callback(res.data)
  }
  //1015199
  function onBusinessLicenseChangeItem(item) {
    form.licenseNumber = item?.licenseNumber ?? ''
    form.businessLicenseName = item?.businessLicenseName ?? ''
    form.bossId = item?.bossId ?? ''
    form.bossName = item?.realName ?? ''
    form.bossNumber = item?.bossNumber ?? ''
    form.authorizedBrandName = ''
    form.authorizedBrandNum = ''
    form.remarkMap = getRemarkMap([])
    form.shopId = ''
    form.shopNumber = ''
    form.shopManageFormNumber = ''
    form.shopManageFormTitle = ''
    form.shopManageFormZSNumber = ''
    form.dianjiangDesigner = ''
    form.dianjiangDesignerName = ''
    form.dianjiangDesignerPhone = ''
    form.seriesNames = ''
    form.smallRegionCode = ''
    form.smallRegionName = ''
    form.authorizedCityCode = ''
    form.authorizedCityName = ''
    form.marketLevelCode = ''
    form.marketLevelName = ''
    form.marketType = ''
    form.shopAddress = ''
  }
  return {
    getBusinessLicenseDataList,
    onBusinessLicenseNotFound,
    onBusinessLicenseChangeItem
  }
}
const {
  getAuthorizedBrandDataList,
  onAuthorizedBrandNotFound,
  onAuthorizedBrandChangeItem,
  getAuthorizedbrandParams
} = useAuthorizedBrand()
function useAuthorizedBrand() {
  const getAuthorizedbrandParams = computed(() => {
    return {
      businessLicenseId: form.businessLicenseId,
      brandId: '1390119720428154881'
    }
  })
  async function getAuthorizedBrandDataList({
    page,
    searchText,
    getDataListParams
  }) {
    const res = await getAuthorizedbrandListPage({
      currPage: page,
      shopBrandNumber: searchText,
      ...getDataListParams
    })
    return res.data
  }
  async function onAuthorizedBrandNotFound(
    { value, getDataListParams },
    callback
  ) {
    const res = await getAuthorizedbrandListPage({
      currPage: 1,
      ...getDataListParams,
      shopBrandNumber: value
    })
    callback(res.data)
  }
  async function onAuthorizedBrandChangeItem(item) {
    form.authorizedBrandName = item?.authorizedName ?? ''
    if (item?.shopBrandNumber) {
      const res = await requestGetPickUpByAuthorizedBrand({
        SHIP_TO_PARTY_CODE: item.shopBrandNumber
      })
      console.log('res', res)
      form.remarkMap = getRemarkMap(res?.data)
    }
    form.shopId = ''
    form.shopNumber = ''
    form.shopManageFormNumber = ''
    form.shopManageFormTitle = ''
    form.shopManageFormZSNumber = ''
    form.dianjiangDesigner = ''
    form.dianjiangDesignerName = ''
    form.dianjiangDesignerPhone = ''
    form.seriesNames = ''
    form.smallRegionCode = ''
    form.smallRegionName = ''
    form.authorizedCityCode = ''
    form.authorizedCityName = ''
    form.marketLevelCode = ''
    form.marketLevelName = ''
    form.marketType = ''
    form.shopAddress = ''
  }
  return {
    getAuthorizedBrandDataList,
    onAuthorizedBrandNotFound,
    onAuthorizedBrandChangeItem,
    getAuthorizedbrandParams
  }
}
const { getShopParams, getShopDataList, onShopNotFound, onShopChangeItem } =
  useShop()
function useShop() {
  const getShopParams = computed(() => {
    return {
      shopBrandNumber: form.authorizedBrandNum
    }
  })
  async function getShopDataList({ page, searchText, getDataListParams }) {
    const res = await getShopByAuthorizedbrandListPage({
      currPage: page,
      shopName: searchText,
      ...getDataListParams,
      shopStatus: 0
    })
    return res.data.list
  }
  async function onShopNotFound({ value, getDataListParams }, callback) {
    const res = await getShopByAuthorizedbrandListPage({
      currPage: 1,
      shopNumber: value,
      ...getDataListParams,
      shopStatus: 0
    })
    callback(res.data.list)
  }
  async function onShopChangeItem(item) {
    form.shopId = item?.id ?? ''
    form.shopManageFormNumber = ''
    form.shopManageFormTitle = ''
    form.shopManageFormZSNumber = ''
    form.dianjiangDesigner = ''
    form.dianjiangDesignerName = ''
    form.dianjiangDesignerPhone = ''
    form.seriesNames = item?.brandNames ?? ''
    form.authorizedCityCode = item?.authorizedCityCode ?? ''
    form.authorizedCityName = item?.authorizedCityName ?? ''
    form.marketLevelCode = item?.marketLevelCode ?? ''
    form.marketLevelName = item?.marketLevelName ?? ''
    form.marketType = item?.marketType ?? ''

    const {
      province = '',
      city = '',
      area = '',
      street = '',
      shopTypeValue = '',
      shopFloor = '',
      shopFloorNumber = ''
    } = item ? item : {}
    /** 省+市+区+街道+渠道性质二级分类+楼层+楼层编码*/
    form.shopAddress = `${province}${city}${area}${street}${shopTypeValue}${shopFloor}${shopFloorNumber}`
    if (item?.authorizedCityCode) {
      const {
        data: { smallCode = '', smallName = '' }
      } = await requestGetSizeBarListByDeptMerge({
        authorizedCityCode: item.authorizedCityCode,
        deptId: '',
        brandIds: '1390119720428154881'
      })
      form.smallRegionCode = smallCode
      form.smallRegionName = smallName
    } else {
      form.smallRegionCode = ''
      form.smallRegionName = ''
    }
  }
  return {
    getShopParams,
    getShopDataList,
    onShopNotFound,
    onShopChangeItem
  }
}
const {
  getDealerShopManageFormList,
  onDealerShopManageFormNotFound,
  onChangeDealerShopManageForm,
  getDealerShopManageFormParams
} = useDealerShopManageForm()
function useDealerShopManageForm() {
  const getDealerShopManageFormParams = computed(() => {
    return {
      searchShop: form.shopId
    }
  })
  async function getDealerShopManageFormList({
    page,
    searchText,
    getDataListParams
  }) {
    const res = await getShopListPage({
      currPage: page,
      orderNumber: searchText,
      processCode: 'JXS020',
      status: 40,
      ...getDataListParams
    })
    return res.data
  }
  async function onDealerShopManageFormNotFound(
    { value, getDataListParams },
    callback
  ) {
    const res = await getShopListPage({
      currPage: 1,
      orderNumber: value,
      processCode: 'JXS020',
      status: 40,
      ...getDataListParams
    })
    callback(res.data)
  }
  async function onChangeDealerShopManageForm(item) {
    console.log(item)
    form.shopManageFormTitle = item?.title ?? ''
    form.shopManageFormZSNumber = item?.zsOrderNumber ?? ''
    if (item?.id) {
      const res = await requestGetFlowDetail(item.id)
      const {
        dianjiangDesigner = '',
        dianjiangDesignerName = '',
        dianjiangDesignerPhone = ''
      } = res.data.shop
      form.dianjiangDesigner = dianjiangDesigner
      form.dianjiangDesignerName = dianjiangDesignerName
      form.dianjiangDesignerPhone = dianjiangDesignerPhone
    } else {
      form.dianjiangDesigner = ''
      form.dianjiangDesignerName = ''
      form.dianjiangDesignerPhone = ''
    }
  }
  return {
    getDealerShopManageFormList,
    onDealerShopManageFormNotFound,
    onChangeDealerShopManageForm,
    getDealerShopManageFormParams
  }
}
const {
  requestJindieDataList,
  onJindieDataNotFound,
  onJindieValueOutputTranslate,
  onJindieValueTranslate
} = useJindieData()
function useJindieData() {
  async function requestJindieDataList({ page, searchText }) {
    const res = await requestJinDieHr({
      page,
      personName: searchText
    })
    return res?.data ?? []
  }
  async function onJindieDataNotFound({ value: personNums }, callback) {
    const requestData = personNums.map(personNum =>
      requestJinDieHr({
        page: 1,
        personNum
      })
    )
    const results = await Promise.all(requestData)
    const data = results.map(res => res.data).flat()
    callback(data)
  }
  function onJindieValueOutputTranslate(value) {
    return Array.isArray(value) ? value.join(',') : ''
  }
  function onJindieValueTranslate(value) {
    return typeof value === 'string' && value !== '' ? value.split(',') : []
  }
  return {
    requestJindieDataList,
    onJindieDataNotFound,
    onJindieValueOutputTranslate,
    onJindieValueTranslate
  }
}
const onApproval = async (bpmExecuteBean, applyOrderModel) => {
  // console.log('审批',bpmExecuteBean, applyOrderModel)
  if (Number(form.status) === 20) {
    form.applyOrderModel = {
      ...form.applyOrderModel,
      ...applyOrderModel,
      bpmExecuteBean
    }
    return flowFormRef.value.onCommit('submit')
  }

  return classicalVMDApi.submitApproveData({
    id: routeQuery.id || routeQuery.businessId,
    optUserId: user.accountId,
    optUserName: user.realName,
    optJobNumber: user.code || user.account,
    approveData: form.approveData,
    applyOrderModel: {
      ...form.applyOrderModel,
      ...applyOrderModel,
      approveAccount: user.code || user.account,
      bpmExecuteBean
    }
  })
}
const getDetail = async (type, cachedFormData) => {
  let initFormData = cachedFormData
  // 编辑状态
  if (type === 'detail') {
    const { data } = await classicalVMDApi.getDetail({
      formId: routeQuery.id || routeQuery.businessId
    })
    initFormData = data
  }

  if (initFormData) {
    for (const key in form) {
      if (initFormData[key] !== undefined) {
        form[key] = initFormData[key]
      }
    }
  }

  if (user && !isOA.value) {
    form.optUserId = form.optUserId || user.accountId
    form.optJobNumber = form.optJobNumber || user.code
    form.optUserName = form.optUserName || user.realName
    form.optDeptId = form.optDeptId || user.deptId
    form.optDeptName = form.optDeptName || user.deptName
  }
}
const onSubmit = async type => {
  const time = dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  form.optTime = time
  form.applyOrderModel.approveAccount = user.code || user.account
  form.applyOrderModel.fdFormTemplateCode = 'distributor-JXS036'
  form.applyOrderModel.pageVersion = 'V1'
  form.applyOrderModel.systemCode = 'distributor'
  if (type !== 'submit') {
    return classicalVMDApi.saveDraft(form)
  }
  return classicalVMDApi.submit(form)
}
</script>

<style lang="scss"></style>
