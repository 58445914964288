<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <!-- <el-tab-pane label="区域配置" name="first">
        <AreaPersonShip v-if="isFirst" :bigBrandMap="bigBrandMap" />
      </el-tab-pane> -->
      <el-tab-pane label="区域部门配置" name="second"
        ><AreaDepartmentShipNew
          v-if="isSecond"
          :bigBrandMap="bigBrandMap"
          :seriesMap="seriesMap" />
      </el-tab-pane>
      <!-- <el-tab-pane label="表单区域关系" name="third"
        ><ArerForm v-if="isThird" :bigBrandMap="bigBrandMap" />
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import AreaDepartmentShipNew from './components/AreaDepartmentShipNew.vue'
// import AreaPersonShip from './components/areaPersonShip.vue'
// import ArerForm from './components/arerForm.vue'
import { brandseriesList } from '@/api/common/'
export default {
  components: {
    AreaDepartmentShipNew,
    // AreaPersonShip,
    // ArerForm
  },
  watch: {},
  computed: {},
  data() {
    return {
      activeName: 'second',
      // isFirst: true,
      isSecond: true,
      isThird: false,
      bigBrandMap: [],
      seriesMap: []
    }
  },
  async mounted() {
    this.getSomeData()
  },
  methods: {
    async getSomeData() {
      try {
        const [bigBrandRes, seriesRes] = await Promise.all([
          brandseriesList({ brandLevel: 0, ifDelete: 0 }),
          brandseriesList({ brandLevel: 1, ifDelete: 0 })
        ])
        this.bigBrandMap = bigBrandRes.data
        this.seriesMap = seriesRes.data
      } catch (error) {
        console.error('Error fetching data:', error)
        this.$message.error('获取数据失败，请稍后再试')
      }
    },
    handleClick(tab) {
      // this.isFirst = tab.name === 'first'
      this.isSecond = tab.name === 'second'
      this.isThird = tab.name === 'third'
    }
  }
}
</script>

<style scoped lang="scss"></style>
