<template>
  <required-form-item
    class="required-form-item-date-picker"
    :class="{ 'required-form-item-oa-date-picker': isOA }"
    v-bind="attrs.other"
    :disabled="isDisabled"
    v-on="$listeners">
    <el-date-picker
      v-model="inputValue"
      value-format="yyyy-MM-dd HH:mm:ss"
      :type="attrs.datePicker?.type || 'date'"
      :placeholder="placeholderText"
      v-bind="attrs.datePicker"
      :disabled="isDisabled"
      v-on="listeners" />
  </required-form-item>
</template>

<script>
import requiredFormItem from './requiredFormItem'
import { FormItem } from 'element-ui'
import { mapGetters } from 'vuex'
const toCamelString = text => {
  const keyArr = text.split('-')
  let camelKey = keyArr[0]

  for (let i = 1, len = keyArr.length; i < len; i++) {
    camelKey += keyArr[i].replace(/^./, keyArr[i][0].toLocaleUpperCase())
  }
  return camelKey
}
export default {
  components: { requiredFormItem },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  inject: {
    bpmFlow: {
      default: undefined
    },
    oaFlow: {
      default: undefined
    }
  },
  props: {
    value: '',
    disabled: {
      type: Boolean,
      default: undefined
    },
    disabledPrior: {
      type: Boolean,
      default: undefined
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  computed: {
    ...mapGetters('dealershipTransfer', ['isOA']),
    isDisabled({ disabledPrior, disabled, bpmFlow, oaFlow, isOA }) {
      if (disabledPrior !== undefined) {
        return disabledPrior
      }
      if (!bpmFlow && isOA) {
        return true
      }
      return oaFlow?.disabled || bpmFlow?.disabled || disabled
    },
    placeholderText({ disabledPrior, placeholder, isOA }) {
      if (isOA && disabledPrior === undefined) {
        return ''
      }
      return placeholder === null
        ? this.$attrs['label'] && `请选择${this.$attrs['label']}`
        : placeholder
    },
    attrs({ $attrs }) {
      const formItemKeys = Object.keys(FormItem.props)
        .concat(Object.keys(requiredFormItem.props))
        .map(toCamelString)
      const datePicker = {
        ...($attrs['datePickerProps'] || {}),
        ...($attrs['date-picker-props'] || {})
      }
      const other = {}
      const blackList = ['datePickerListeners', 'datePickerProps']
      for (const key in $attrs) {
        const camelKey = toCamelString(key)
        if (formItemKeys.includes(camelKey)) {
          other[key] = $attrs[key]
        } else if (!blackList.includes(camelKey)) {
          datePicker[key] = $attrs[key]
        }
      }

      return {
        other,
        datePicker
      }
    },
    listeners({ $listeners, $attrs }) {
      return Object.assign(
        $listeners,
        $attrs['date-picker-listeners'],
        $attrs['datePickerListeners']
      )
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue: {
      immediate: true,
      handler(value) {
        this.$emit('changeValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.required-form-item-date-picker {
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}
.required-form-item-oa-date-picker {
  ::v-deep {
    .el-input.is-disabled {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border-bottom-color: rgba(0, 0, 0, 0.06);
        color: #333;
        cursor: default;
        padding-left: 0;
      }
      .el-input__prefix {
        display: none;
      }
    }
  }
}
</style>
